html,
body {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  width: 100vw;
}

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Euclid Circular A',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-Light-WebS.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-LightItalic-WebS.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-Regular-WebS.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-RegularItalic-WebS.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-Medium-WebS.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-MediumItalic-WebS.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-Semibold-WebS.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-SemiboldItalic-WebS.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-Bold-WebS.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./fonts/EuclidCircularA-BoldItalic-WebS.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

